.message-editor {
  
  ul[data-type="taskList"] {
      list-style: none;
      padding: 0;
    
      li {
        display: flex;
        align-items: center;
    
        > label {
          flex: 0 0 auto;
          margin-right: 0.5rem;
        }
      }
    
      input[type="checkbox"] {
        cursor: pointer;
      }
    }
    
    blockquote {
      border-left: solid;
      border-color: grey;
      padding-left: 5px;
    }
  
    .ProseMirror {
      > * + * {
        margin-top: 0.75em;
      }
  
      /* Placeholder (at the top) */
      .ProseMirror p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #ced4da;
        pointer-events: none;
        height: 0;
      }
  
      img {
        max-width: 100%;
        height: auto;
    
        &.ProseMirror-selectednode {
          outline: 3px solid #68CEF8;
        }
      }
    
      pre {
        background: #0D0D0D;
        color: #FFF;
        font-family: 'JetBrainsMono', monospace;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
    
        code {
          color: inherit;
          padding: 0;
          background: none;
          font-size: 0.8rem;
        }
    
        .hljs-comment,
        .hljs-quote {
          color: #616161;
        }
    
        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class {
          color: #F98181;
        }
    
        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
          color: #FBBC88;
        }
    
        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
          color: #B9F18D;
        }
    
        .hljs-title,
        .hljs-section {
          color: #FAF594;
        }
    
        .hljs-keyword,
        .hljs-selector-tag {
          color: #70CFF8;
        }
    
        .hljs-emphasis {
          font-style: italic;
        }
    
        .hljs-strong {
          font-weight: 700;
        }
      }
    }
  
    .ProseMirror:focus {
      outline: none;
  }
}
